<script>
export default {
    props: {
        type: {
            type: String
        },
        placeholder: {
            type: String
        },
        inputName: {
            type: String
        },
        label: {
            type: String
        },
        onlyNumbers: {
            type: Boolean
        },
        currencyMask: {
            type: Boolean
        },
        agencyBankMask: {
            type: Boolean
        },
        modelValue: {
            type: String
        },
        value: {
            type: String
        },
        disabled: {
            type: Boolean
        },
        maxLength: {
            type: Number
        },
        visibilyPassword: {
            type: Boolean,
            default: false
        },
    },
    emits: ["update:modelValue"],
    data() {
        return {
            modelValue: '',
            typePassword: 'password',
        }
    },
    methods: {
        emitContent(event) {
            if (this.agencyBankMask) {
                const newValue = event.target.value.replace(/\D/g, '');
                    event.target.value = newValue;

                    var n = undefined;
                    
                    if (newValue.length > 4) {
                        n = newValue.slice(0, -1) + '-' + newValue.slice(-1);

                        return this.$emit('update:modelValue', n);
                    }

                    return this.$emit('update:modelValue', newValue);
            }

            if (this.currencyMask) {
                    const newValue = event.target.value.replace(/\D/g, '');
                    event.target.value = newValue;

                    var n = undefined;
                    
                    if (newValue.length > 2) {
                        n = newValue.slice(0, -2) + '.' + newValue.slice(-2);

                        return this.$emit('update:modelValue', n);
                    }

                    return this.$emit('update:modelValue', newValue);
                }

            if (this.onlyNumbers) {
                const newValue = event.target.value.replace(/\D/g, '');
                event.target.value = newValue;

                this.$emit('update:modelValue', newValue);
            } else {
                this.$emit('update:modelValue', event.target.value);
            }
        },
    }
}
</script>

<template>
    <section class="bg-input">
        <label :for="inputName" class="label-input">{{ label }}</label>

        <div class="group-input">
            <!-- <input :type="type" :placeholder="placeholder" :name="inputName" class="input" @input="emitContent" :input="modelValue" :value="value" :disabled="disabled" :maxlength="maxLength" /> -->

            <input :type="visibilyPassword ? typePassword : type" :placeholder="placeholder" :name="inputName" class="input" @input="emitContent" :input="modelValue" :value="value" :disabled="disabled" :maxlength="maxLength"  />

            <div v-if="visibilyPassword" @click="typePassword == 'password' ? typePassword = 'text' : typePassword = 'password'" class="change-visibility">
                <svg v-if="typePassword == 'password'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>

                <svg v-if="typePassword == 'text'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                </svg>
            </div>

            <div v-if="searchButton" @click="clickSearchButton" class="change-visibility">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                </svg>
            </div>
        </div>
    </section>
</template>

<style scoped>
.bg-input {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.bg-input .label-input {
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-color);
    margin-bottom: 6px;
}

.bg-input .input {
    width: 100%;
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    padding: 14px 12px;
    font-size: 16px;
}

.group-input {
    width: 100%;
    position: relative;
}

.change-visibility {
    position: absolute;
    right: 0px;
    top: 3px;
    width: 50px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.change-visibility .icon {
    width: 20px;
}
</style>